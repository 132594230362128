var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var Youtube = function () { return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M14.7188 12.0094L10.2563 9.47344V14.5453L14.7188 12.0094ZM19.5 1.5H4.5C2.84531 1.5 1.5 2.84531 1.5 4.5V19.5C1.5 21.1547 2.84531 22.5 4.5 22.5H19.5C21.1547 22.5 22.5 21.1547 22.5 19.5V4.5C22.5 2.84531 21.1547 1.5 19.5 1.5ZM20.175 7.87969C20.5312 9.22031 20.5312 12.0141 20.5312 12.0141C20.5312 12.0141 20.5312 14.8078 20.175 16.1437C19.9781 16.8844 19.4016 17.4422 18.6656 17.6391C17.3391 18 12 18 12 18C12 18 6.66094 18 5.33438 17.6437C4.59844 17.4469 4.02187 16.8891 3.825 16.1484C3.46875 14.8078 3.46875 12.0141 3.46875 12.0141C3.46875 12.0141 3.46875 9.21563 3.825 7.87969C4.02187 7.13906 4.59844 6.55781 5.33438 6.36094C6.66094 6 12 6 12 6C12 6 17.3391 6 18.6656 6.36094C19.4016 6.55781 19.9781 7.13906 20.175 7.87969Z", fill: "#EEEEEE" }) }))); };
