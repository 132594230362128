var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FEATURE_FLAGS } from '../../util/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import styles from './TabNav.module.scss';
import { useTranslation } from 'react-i18next';
import { Posts } from '../Icons/Posts';
import { Settings } from '../Icons/Settings';
import { Chat } from '../Icons/Chat';
import { Notifications } from '../Icons/Notifications';
import api from '../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationUnRead } from '../../redux/modules/notifications';
var TabNav = function () {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var defaultBadge = useSelector(function (_a) {
        var _b, _c;
        var notifications = _a.notifications;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (_c = (_b = notifications === null || notifications === void 0 ? void 0 : notifications.history) === null || _b === void 0 ? void 0 : _b.unreadCount) !== null && _c !== void 0 ? _c : 0;
    });
    var _a = useState(defaultBadge), badgeCount = _a[0], setBadgeCount = _a[1];
    var navigationItems = [
        {
            to: '/all-posts',
            label: t('posts.label'),
            icon: _jsx(Posts, {}),
        },
        {
            to: '/settings',
            label: t('settings.label'),
            icon: _jsx(Settings, {}),
        },
    ];
    if (FEATURE_FLAGS.savedPosts) {
        navigationItems.splice(navigationItems.length - 1, 0, {
            to: '/saved',
            label: t('saved.label'),
            icon: _jsx(FontAwesomeIcon, { icon: ['far', 'bookmark'] }),
        });
    }
    if (FEATURE_FLAGS.notifications) {
        navigationItems.splice(navigationItems.length - 1, 0, {
            to: '/notifications',
            label: t('notifications.label'),
            icon: _jsx(Notifications, {}),
            badgeCount: badgeCount,
        });
    }
    if (FEATURE_FLAGS.chat) {
        navigationItems.splice(navigationItems.length - 1, 0, {
            to: '/chat',
            label: t('chat.label'),
            icon: _jsx(Chat, {}),
        });
    }
    useEffect(function () {
        api
            .notificationsGetHistory(1000, 1, true)
            .then(function (_a) {
            var _b;
            var results = _a.results;
            var unreadCount = (_b = results === null || results === void 0 ? void 0 : results.filter(function (notification) { return !notification.read; }).length) !== null && _b !== void 0 ? _b : 0;
            dispatch(fetchNotificationUnRead(unreadCount));
            setBadgeCount(unreadCount);
        })
            .catch(function () {
            dispatch(fetchNotificationUnRead(0));
            setBadgeCount(0);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", __assign({ className: styles.wrapper }, { children: _jsx("ul", __assign({ className: styles.menu }, { children: navigationItems.map(function (_a) {
                var to = _a.to, label = _a.label, icon = _a.icon, badgeCount = _a.badgeCount;
                return (_jsx("li", { children: _jsxs(Link, __assign({ to: to, activeClassName: styles.active }, { children: [badgeCount && badgeCount > 0 ? (_jsx("div", __assign({ className: styles.badge }, { children: badgeCount }))) : null, icon, _jsx("div", __assign({ className: styles.label }, { children: label }))] })) }, label));
            }) })) })));
};
export default TabNav;
