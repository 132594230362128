var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './FieldStatusBlock.module.scss';
import clsx from 'clsx';
var FieldStatusBlock = function (props) {
    var _a = props.displayBlock, displayBlock = _a === void 0 ? true : _a, error = props.error, _b = props.touched, touched = _b === void 0 ? false : _b, _c = props.focused, focused = _c === void 0 ? false : _c, _d = props.light, light = _d === void 0 ? false : _d, children = props.children, className = props.className;
    var statusBlockClasses = [
        displayBlock && styles.statusBlock,
        light && styles.light,
        focused && styles.focused,
        !focused && touched && error && styles.error,
    ].join(' ');
    return (_jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsx("div", __assign({ className: clsx(styles.blockWrapper, className && className) }, { children: _jsx("div", __assign({ className: statusBlockClasses }, { children: children })) })), touched && error && _jsx("div", __assign({ className: styles.errorMessage }, { children: error }))] })));
};
export default FieldStatusBlock;
