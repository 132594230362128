import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldLabel.module.scss';
import { useTranslation } from 'react-i18next';

const FieldLabel = props => {
  const { label, required, disabled, light, className } = props;
  const wrapperClasses = [
    styles.wrapper,
    disabled && styles.disabled,
    light && styles.light,
    className && className,
  ].join(' ');
  const { t } = useTranslation();
  return (
    <div className={wrapperClasses}>
      {required ? (
        <>
          {label} <span>({t('required.label').toLowerCase()})</span>
        </>
      ) : (
        label
      )}
    </div>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
  className: PropTypes.string,
};

FieldLabel.defaultProps = {
  required: false,
  disabled: false,
  light: false,
};

export default FieldLabel;
