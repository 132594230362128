var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './UIListItem.module.scss';
import { connect } from 'react-redux';
import clsx from 'clsx';
var UIListItem = function (_a) {
    var _b = _a.icon, icon = _b === void 0 ? null : _b, _c = _a.title, title = _c === void 0 ? null : _c, _d = _a.to, to = _d === void 0 ? null : _d, _e = _a.link, link = _e === void 0 ? null : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, onClick = _a.onClick, onKeyUp = _a.onKeyUp, history = _a.history, children = _a.children, className = _a.className, activeButton = _a.activeButton, contentClass = _a.contentClass;
    var handleNavigate = function () {
        if (to) {
            history.push(to);
            // window.open(to, "_self");
        }
        else if (link) {
            window.open(link, '_blank');
        }
    };
    var handleClick = function (e) {
        if (to || link) {
            handleNavigate();
        }
        else if (onClick) {
            onClick(e);
        }
    };
    var handleKeyUp = function (e) {
        onkeyup ? onKeyUp(e) : null;
        e.keyCode === 13 ? handleNavigate() : null;
    };
    var listStyles = [
        styles.listItem,
        icon && styles.withIcon,
        to && styles.withLink,
        className,
    ].join(' ');
    var mainStyles = [styles.main, title && styles.withTitle].join(' ');
    return (_jsx("div", __assign({ className: clsx(styles.wrapper, className, activeButton && styles.activeButton) }, { children: _jsxs("div", __assign({ className: listStyles, onClick: function (e) { return (!disabled ? handleClick(e) : null); }, onKeyUp: function (e) { return (!disabled ? handleKeyUp(e) : null); }, role: "button", tabIndex: 0 }, { children: [icon && icon, _jsxs("div", __assign({ className: mainStyles }, { children: [title && _jsx("div", __assign({ className: styles.title }, { children: title })), _jsx("div", __assign({ className: clsx(!contentClass && styles.content, contentClass) }, { children: children }))] })), (link || to) && (_jsx("div", __assign({ className: styles.ctaIcon }, { children: _jsx(FontAwesomeIcon, { icon: ['fas', 'chevron-right'] }) })))] })) })));
};
export default withRouter(connect(null, {})(UIListItem));
