var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var Facebook = function () { return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.5 1.5C2.84531 1.5 1.5 2.84531 1.5 4.5V19.5C1.5 21.1547 2.84531 22.5 4.5 22.5H9.10312V15.6656H6.62813V12H9.10312V10.4203C9.10312 6.3375 10.95 4.44375 14.9625 4.44375C15.7219 4.44375 17.0344 4.59375 17.5734 4.74375V8.0625C17.2922 8.03438 16.8 8.01562 16.1859 8.01562C14.2172 8.01562 13.4578 8.76094 13.4578 10.6969V12H17.3766L16.7016 15.6656H13.4531V22.5H19.5C21.1547 22.5 22.5 21.1547 22.5 19.5V4.5C22.5 2.84531 21.1547 1.5 19.5 1.5H4.5Z", fill: "#EEEEEE" }) }))); };
