import React, { Suspense, lazy, useEffect } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AllChats from '../../pages/AllChats';
import AppErrorBoundary from '../AppErrorBoundary/AppErrorBoundary';
import AppUrlListener from '../../pages/AppUrlListener';
import AuthProvider from '../../context/AuthProvider';
import AutoTranslatePage from '../../pages/AutoTranslate';
import Interests from '../../pages/Interests';
import Loader from '../Loader/Loader';
import { MainProvider } from '../../context/MainContext';
import { Preferences } from '@capacitor/preferences';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { UploadProvider } from '../../context/VideoUploadContext';
import WebRoute from '../WebRoute/WebRoute';
import { appInit } from '../../redux/modules/app';
import { sessionResume } from '../../redux/modules/auth';
import { useTranslation } from 'react-i18next';
import api from '../../util/api';
import {
  useAuth,
  useLiked,
  useProfile,
  useTaxonomyCountry,
  useTaxonomyEthnicity,
  useTaxonomyFlagReasons,
  useTaxonomyGameInvolvement,
  useTaxonomyGender,
  useTaxonomyMedicalStatus,
  useTaxonomyMilitaryService,
  useTaxonomyMilitaryStatus,
  useTaxonomyOpportunities,
  useTaxonomyPastGames,
  useTaxonomyPastTrials,
} from '../../util/APIDjango';

const Root = lazy(() => import('../../pages/Root'));
const InitLoad = lazy(() => import('../../pages/InitLoad'));
const AuthCheck = lazy(() => import('../../pages/AuthCheck'));
const Boards = lazy(() => import('../../pages/Boards'));
const AllPosts = lazy(() => import('../../pages/AllPosts'));
const Posts = lazy(() => import('../../pages/Posts'));
const DocumentsRequested = lazy(() => import('../../pages/DocumentsRequested'));
const Welcome = lazy(() => import('../../pages/Welcome'));
const SignUp = lazy(() => import('../../pages/SignUp'));
const SignUpCompletePage = lazy(() => import('../../pages/SignUpCompletePage'));
const SignIn = lazy(() => import('../../pages/SignIn'));
const SignOut = lazy(() => import('../../pages/SignOut'));
const ForgotPassword = lazy(() => import('../../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../../pages/ResetPassword'));
const Saved = lazy(() => import('../../pages/Saved'));
const Notifications = lazy(() => import('../../pages/Notifications'));
const Settings = lazy(() => import('../../pages/Settings'));
const Account = lazy(() => import('../../pages/Account'));
const BlockedUsers = lazy(() => import('../../pages/BlockedUsers'));
const NotificationPreferences = lazy(() => import('../../pages/NotificationPreferences'));
const PublicInformation = lazy(() => import('../../pages/PublicInformation'));
const LanguageSelection = lazy(() => import('../../pages/LanguageSelection'));
const Information = lazy(() => import('../../pages/Information'));
const Page404 = lazy(() => import('../../pages/Page404'));

const mapStateToProps = ({
  auth,
  taxonomyGender,
  taxonomyCountry,
  taxonomyMilitaryStatus,
  taxonomyMilitaryService,
  taxonomyPastGames,
  taxonomyPastTrials,
  taxonomyMedicalStatus,
  taxonomyOpportunities,
  taxonomyGameInvolvement,
  taxonomyFlagReasons,
}) => {
  const hasLoadedSettings =
    taxonomyGender.isFetched &&
    taxonomyCountry.isFetched &&
    taxonomyMilitaryStatus.isFetched &&
    taxonomyMilitaryService.isFetched &&
    taxonomyPastGames.isFetched &&
    taxonomyPastTrials.isFetched &&
    taxonomyMedicalStatus.isFetched &&
    taxonomyOpportunities.isFetched &&
    taxonomyGameInvolvement.isFetched &&
    taxonomyFlagReasons.isFetched;

  return {
    hasLoadedSettings,
    reAuthenticating: auth.reAuthenticating,
  };
};

const App = () => {
  const dispatch = useDispatch();

  const [authState, setAuthState] = useAuth();
  const [taxonomyCountryState, setTaxonomyCountryState] = useTaxonomyCountry();
  const [taxonomyGenderState, setTaxonomyGenderState] = useTaxonomyGender();
  const [taxonomyMilitaryServiceState, setTaxonomyMilitaryServiceState] =
    useTaxonomyMilitaryService();
  const [taxonomyMilitaryStatusState, setTaxonomyMilitaryStatusState] = useTaxonomyMilitaryStatus();
  const [taxonomyFlagReasonsState, setTaxonomyFlagReasonsState] = useTaxonomyFlagReasons();
  const [taxonomyPastGamesState, setTaxonomyPastGamesState] = useTaxonomyPastGames();
  const [taxonomyPastTrialsState, setTaxonomyPastTrialsState] = useTaxonomyPastTrials();
  const [taxonomyOpportunitiesState, setTaxonomyOpportunitiesState] = useTaxonomyOpportunities();
  const [taxonomyMedicalStatusState, setTaxonomyMedicalStatusState] = useTaxonomyMedicalStatus();
  const [taxonomyGameInvolvementState, setTaxonomyGameInvolvementState] =
    useTaxonomyGameInvolvement();
  const [taxonomyEthnicityState, setTaxonomyEthnicityState] = useTaxonomyEthnicity();
  const [likedState, setLikedState] = useLiked();

  const [profileData, setProfileData] = useProfile();

  const { i18n } = useTranslation('', { useSuspense: false });
  let routes;
  const { reAuthenticating, hasLoadedSettings } = useSelector(mapStateToProps);

  const taxonomyMap = {
    Country: api.taxonomyCountry,
    Gender: api.taxonomyGender,
    Ethnicity: api.taxonomyEthnicity,
    MilitaryService: api.taxonomyMilitaryService,
    MilitaryStatus: api.taxonomyMilitaryStatus,
    FlagReasons: api.taxonomyFlagReasons,
    PastGames: api.taxonomyPastGames,
    PastTrials: api.taxonomyPastTrials,
    Opportunities: api.taxonomyOpportunities,
    MedicalStatus: api.taxonomyMedicalStatus,
    GameInvolvement: api.taxonomyGameInvolvement,
  };

  const updateTaxonomyState = (taxonomy, newState) => {
    switch (taxonomy) {
      case 'Country':
        setTaxonomyCountryState({ ...taxonomyCountryState, ...newState });
        break;
      case 'Gender':
        setTaxonomyGenderState({ ...taxonomyGenderState, ...newState });
        break;
      case 'Ethnicity':
        setTaxonomyEthnicityState({ ...taxonomyEthnicityState, ...newState });
        break;
      case 'MilitaryService':
        setTaxonomyMilitaryServiceState({
          ...taxonomyMilitaryServiceState,
          ...newState,
        });
        break;
      case 'MilitaryStatus':
        setTaxonomyMilitaryStatusState({
          ...taxonomyMilitaryStatusState,
          ...newState,
        });
        break;
      case 'FlagReasons':
        setTaxonomyFlagReasonsState({
          ...taxonomyFlagReasonsState,
          ...newState,
        });
        break;
      case 'PastGames':
        setTaxonomyPastGamesState({ ...taxonomyPastGamesState, ...newState });
        break;
      case 'PastTrials':
        setTaxonomyPastTrialsState({ ...taxonomyPastTrialsState, ...newState });
        break;
      case 'Opportunities':
        setTaxonomyOpportunitiesState({
          ...taxonomyOpportunitiesState,
          ...newState,
        });
        break;
      case 'MedicalStatus':
        setTaxonomyMedicalStatusState({
          ...taxonomyMedicalStatusState,
          ...newState,
        });
        break;
      case 'GameInvolvement':
        setTaxonomyGameInvolvementState({
          ...taxonomyGameInvolvementState,
          ...newState,
        });
        break;
      default:
        console.log('Invalid taxonomy type');
    }
  };

  const reAuth = () =>
    setAuthState({
      ...authState,
      authenticating: true,
      reAuthenticating: true,
      authenticated: false,
      unauthenticating: false,
      error: null,
    });

  const reAuthFulfill = uidReturned =>
    setAuthState({
      ...authState,
      uid: uidReturned,
      authenticated: true,
      authenticating: false,
      reAuthenticating: false,
      unauthenticating: false,
      error: null,
    });

  const reAuthReject = () =>
    setAuthState({
      ...authState,
      authenticated: false,
      authenticating: false,
      reAuthenticating: false,
    });

  const authenticateSession = () => {
    // to re-auth the session
    reAuth();
    api
      .resumeSession()
      .then(uidReturned => {
        reAuthFulfill(uidReturned);
      })
      .catch(err => {
        reAuthReject(err.message);
      });
  };

  const fetchTaxonomies = async () => {
    const fetchTaxonomy = async taxonomy => {
      updateTaxonomyState(taxonomy, { isFetching: true });

      if (!taxonomyMap[taxonomy]) {
        throw new Error(`Unsupported taxonomy: ${taxonomy}`);
      }

      try {
        const terms = await taxonomyMap[taxonomy]();
        updateTaxonomyState(taxonomy, {
          terms,
          isFetched: true,
          isFetching: false,
          error: null,
        });
      } catch (err) {
        console.error(`Error fetching taxonomy ${taxonomy}:`, err);
        updateTaxonomyState(taxonomy, { isFetching: false, error: err });
      }
    };

    await Promise.all([
      fetchTaxonomy('Gender'),
      fetchTaxonomy('Country'),
      fetchTaxonomy('Ethnicity'),
      fetchTaxonomy('MilitaryService'),
      fetchTaxonomy('MilitaryStatus'),
      fetchTaxonomy('FlagReasons'),
      fetchTaxonomy('PastGames'),
      fetchTaxonomy('PastTrials'),
      fetchTaxonomy('Opportunities'),
      fetchTaxonomy('MedicalStatus'),
      fetchTaxonomy('GameInvolvement'),
    ]);
  };

  // Profile fetch

  const getProfileData = () => setProfileData({ ...profileData, isFetching: true });

  const profileDataFulfill = profileData =>
    setProfileData({
      ...profileData,
      profile: profileData,
      isFetching: false,
      isFetched: true,
      error: null,
    });

  const profileDataReject = error =>
    setProfileData({ ...profileData, error: error, isFetching: false });

  useEffect(() => {
    getProfileData();
    api
      .profileGet()
      .then(profileData => {
        handleInitialLikes(profileData);
        profileDataFulfill(profileData);
      })
      .catch(err => {
        profileDataReject(err.message);
      });
  }, []);

  // Handling Post Likes

  const handleInitialLikes = profileData => {
    const { postLikes } = profileData;
    const postArray = postLikes?.length ? postLikes.map(elem => elem.post) : [];

    setLikedState({
      ...likedState,
      posts: postArray,
      updating: false,
      error: null,
    });
  };

  useEffect(() => {
    dispatch(appInit());
    // dispatch(sessionResume());
    authenticateSession();
    fetchTaxonomies();

    void Preferences.get({ key: 'language' }).then(result => {
      if (result.value) {
        void i18n.changeLanguage(result.value).then(() => {
          console.log('Language has been changed');
        });
      }
    });
  }, []);
  if (!hasLoadedSettings) {
    routes = (
      <Switch>
        <Route path="/" component={InitLoad} />
      </Switch>
    );
  } else if (reAuthenticating) {
    routes = (
      <Switch>
        <Route path="/" component={AuthCheck} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <PrivateRoute exact path="/" component={Root} />
        <PrivateRoute exact path="/chat" component={AllChats} />
        <PrivateRoute exact path="/chat/:chatId" component={AllChats} />
        <PrivateRoute exact path="/chat/:chatId/:messageId" component={AllChats} />
        <PrivateRoute exact path="/all-posts" component={AllPosts} />
        <PrivateRoute exact path="/all-posts/:itemId" component={AllPosts} />
        <PrivateRoute exact path="/all-posts/:itemId/:commentId" component={AllPosts} />
        <PrivateRoute exact path="/cat-posts/:catSlug" component={AllPosts} />
        <PrivateRoute exact path="/boards" component={Boards} />
        <PrivateRoute exact path="/boards/:boardId" component={Posts} />
        <PrivateRoute exact path="/boards/:boardId/:itemId" component={Posts} />
        <PrivateRoute exact path="/boards/:boardId/:itemId/:commentId" component={Posts} />
        <PrivateRoute exact path="/saved" component={Saved} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute exact path="/blocked-users" component={BlockedUsers} />
        <PrivateRoute exact path="/notification-preferences" component={NotificationPreferences} />
        <PrivateRoute exact path="/interests" component={Interests} />
        <PrivateRoute exact path="/language-selection" component={LanguageSelection} />
        <PrivateRoute exact path="/auto-translate" component={AutoTranslatePage} />
        <PrivateRoute exact path="/information" component={Information} />
        <WebRoute
          exact
          path="/documents-requested"
          component={DocumentsRequested}
          nonWeb={Page404}
        />
        <WebRoute
          exact
          path="/documents-requested/:ref"
          component={DocumentsRequested}
          nonWeb={Page404}
        />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-up-complete" component={SignUpCompletePage} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/sign-out" component={SignOut} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/public-information" component={PublicInformation} />
        <Route component={Page404} />
      </Switch>
    );
  }

  return (
    <AppErrorBoundary>
      <Suspense fallback={<Loader fullPage />}>
        <AuthProvider>
          <MainProvider>
            <UploadProvider>
              <Router baseline="/">
                <AppUrlListener></AppUrlListener>
                <Suspense fallback={<Loader fullPage />}>{routes}</Suspense>
              </Router>
            </UploadProvider>
          </MainProvider>
        </AuthProvider>
      </Suspense>
    </AppErrorBoundary>
  );
};

export default App;
