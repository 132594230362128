// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ActionButtonGroup-modules-actionButtonGroup-yuxNF{display:flex;flex-direction:row;border-top:1px solid #cfd8dc;margin-top:16px}.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button{background-color:rgba(0,0,0,0);box-shadow:none;flex:1;padding:16px;border:none;border-right:1px solid #cfd8dc;font-size:14px}.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button:last-child{border-right:none}.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button:hover{background-color:rgba(0,0,0,.1)}`, "",{"version":3,"sources":["webpack://./src/components/ActionButtonGroup/ActionButtonGroup.modules.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,oDACE,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,eCDO,CDGP,2DACE,8BAAA,CACA,eAAA,CACA,MAAA,CACA,YAAA,CACA,WAAA,CACA,8BAAA,CACA,cAAA,CAEA,sEACE,iBAAA,CAGF,iEAAA,+BAAA","sourcesContent":["@import \"../../theme/colors\";\n@import \"../../theme/dimensions\";\n\n.actionButtonGroup {\n  display: flex;\n  flex-direction: row;\n  border-top: 1px solid $grey-100;\n  margin-top: $gutter;\n\n  button {\n    background-color: transparent;\n    box-shadow: none;\n    flex: 1;\n    padding: 16px;\n    border: none;\n    border-right: 1px solid $grey-100;\n    font-size: 14px;\n\n    &:last-child {\n      border-right: none;\n    }\n\n    &:hover { background-color: rgba(0,0,0,.1); }\n  }\n}\n","$nav-height: 64px;\n$nav-tab-height: calc(54px + 4px + env(safe-area-inset-bottom));\n\n$gutterExtraSmall: 4px;\n$gutterMediumSmall: 5px;\n$gutterSmall: 8px;\n$gutter: 16px;\n$gutterLarge: 32px;\n$buttonGroupHeight: 152px;\n\n$mobile: 480px;\n$tablet: 768px;\n$desktop: 1020px;\n\n$fieldWidth: 600px;\n\n@mixin max-960 {\n  max-width: 960px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtonGroup": `-ActionButtonGroup-modules-actionButtonGroup-yuxNF`
};
export default ___CSS_LOADER_EXPORT___;
