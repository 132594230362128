var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var GET_CHAT_ROOMS_DISPATCH = 'WIS/chat/GET_CHAT_ROOMS_DISPATCH';
export var GET_CHAT_ROOMS_FULFILL = 'WIS/chat/GET_CHAT_ROOMS_FULFILLED';
export var GET_CHAT_ROOMS_REJECT = 'WIS/chat/GET_CHAT_ROOMS_REJECTED';
export var GET_MESSAGES_FOR_CHAT_DISPATCH = 'WIS/chat/GET_MESSAGES_FOR_CHAT_DISPATCHED';
export var GET_MESSAGES_FOR_CHAT_FULFILL = 'WIS/chat/GET_MESSAGES_FOR_CHAT_FULFILLED';
export var GET_MESSAGES_FOR_CHAT_REJECT = 'WIS/chat/GET_MESSAGES_FOR_CHAT_REJECTED';
export var GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH = 'WIS/chat/GET_NEXT_MESSAGES_FOR_CHAT_DISPATCHED';
export var GET_NEXT_MESSAGES_FOR_CHAT_FULFILL = 'WIS/chat/GET_NEXT_MESSAGES_FOR_CHAT_FULFILLED';
export var GET_NEXT_MESSAGES_FOR_CHAT_REJECT = 'WIS/chat/GET_NEXT_MESSAGES_FOR_CHAT_REJECTED';
export var GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH = 'WIS/chat/GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCHED';
export var GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL = 'WIS/chat/GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILLED';
export var GET_PREVIOUS_MESSAGES_FOR_CHAT_REJECT = 'WIS/chat/GET_PREVIOUS_MESSAGES_FOR_CHAT_REJECTED';
export var ADD_WS_MESSAGE_TO_LIST = 'WIS/chat/ADD_WS_MESSAGE_TO_LIST';
export var ADD_WS_MESSAGE_LIKES_TO_LIST = 'WIS/chat/ADD_WS_MESSAGE_LIKES_TO_LIST';
// export const ADD_WS_MESSAGE_TO_LIST_FULFILL =
//   'WIS/chat/NEW_WS_MESSAGE_TO_LIST_FULFILLED';
// export const ADD_WS_MESSAGE_TO_LIST_REJECT =
//   'WIS/chat/NEW_WS_MESSAGE_TO_LIST_REJECTED';
export var REMOVE_WS_MESSAGE_FROM_LIST = 'WIS/chat/REMOVE_WS_MESSAGE_FROM_LIST';
export var getChatRoomsDispatch = function (id) { return ({
    type: GET_CHAT_ROOMS_DISPATCH,
    id: id,
}); };
// export const getChatRoomsFulfill = (rooms: ChatRoom[]) => ({
//   type: GET_CHAT_ROOMS_FULFILL,
//   rooms,
// });
// export const getChatRoomsReject = (error: string) => ({
//   type: GET_CHAT_ROOMS_REJECT,
//   error,
// });
export var getMessagesForChatDispatch = function (id, options) { return ({
    type: GET_MESSAGES_FOR_CHAT_DISPATCH,
    id: id,
    options: options,
}); };
export var getMessagesForChatFulfill = function (chatId, messages) { return ({
    type: GET_MESSAGES_FOR_CHAT_FULFILL,
    chatId: chatId,
    messages: messages,
}); };
export var getMessagesForChatReject = function (error) { return ({
    type: GET_MESSAGES_FOR_CHAT_REJECT,
    error: error,
}); };
export var getNextMessagesForChatDispatch = function (id, url) { return ({
    type: GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH,
    id: id,
    url: url,
}); };
export var getNextMessagesForChatFulfill = function (chatId, messages) { return ({
    type: GET_NEXT_MESSAGES_FOR_CHAT_FULFILL,
    chatId: chatId,
    messages: messages,
}); };
export var getNextMessagesForChatReject = function (error) { return ({
    type: GET_NEXT_MESSAGES_FOR_CHAT_REJECT,
    error: error,
}); };
export var getPreviousMessagesForChatDispatch = function (id, url) { return ({
    type: GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH,
    id: id,
    url: url,
}); };
export var getPreviousMessagesForChatFulfill = function (chatId, messages) { return ({ type: GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL, chatId: chatId, messages: messages }); };
export var getPreviousMessagesForChatReject = function (error) { return ({
    type: GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL,
    error: error,
}); };
export var addWsMessageToList = function (chatId, message) { return ({
    type: ADD_WS_MESSAGE_TO_LIST,
    chatId: chatId,
    message: message,
}); };
export var addWsMessageLikesToList = function (chatId, likes_count, message_id, isLike) {
    return {
        type: ADD_WS_MESSAGE_LIKES_TO_LIST,
        chatId: chatId,
        likes_count: likes_count,
        message_id: message_id,
        isLike: isLike,
    };
};
export var removeWsMessageFromList = function (chatId, messageId) { return ({
    type: REMOVE_WS_MESSAGE_FROM_LIST,
    chatId: chatId,
    messageId: messageId,
}); };
var initialState = {
    rooms: [],
    messagesByRoom: [],
    isFetching: false,
    isFetched: false,
    messagesFetched: false,
    messagesFetching: false,
};
export default function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    if (state === void 0) { state = initialState; }
    var rooms = state.messagesByRoom.slice();
    switch (action.type) {
        case GET_CHAT_ROOMS_DISPATCH:
            return __assign(__assign({}, state), { isFetching: true, isFetched: false });
        case GET_CHAT_ROOMS_FULFILL:
            return __assign(__assign({}, state), { rooms: action.rooms, isFetched: true, isFetching: false });
        case GET_CHAT_ROOMS_REJECT:
            return __assign(__assign({}, state), { fetchError: action.error, isFetched: true, isFetching: false });
        case GET_MESSAGES_FOR_CHAT_DISPATCH:
            return __assign(__assign({}, state), { messagesFetched: false, messagesFetching: true });
        case GET_MESSAGES_FOR_CHAT_FULFILL:
            rooms[action.chatId] = __assign(__assign({}, action.messages), { results: addMessagesToList(((_a = rooms[action.chatId]) === null || _a === void 0 ? void 0 : _a.results) || [], ((_b = action.messages) === null || _b === void 0 ? void 0 : _b.results) || []) });
            return __assign(__assign({}, state), { messagesByRoom: rooms, messagesFetched: true, messagesFetching: false, lastMessageIdFetched: (_e = (_c = action.messages) === null || _c === void 0 ? void 0 : _c.results[((_d = action.messages) === null || _d === void 0 ? void 0 : _d.results.length) - 1]) === null || _e === void 0 ? void 0 : _e.id });
        case GET_MESSAGES_FOR_CHAT_REJECT:
            return __assign(__assign({}, state), { fetchError: action.error, messagesFetched: true, messagesFetching: false });
        case GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH:
            return __assign(__assign({}, state), { messagesFetched: false, messagesFetching: true });
        case GET_NEXT_MESSAGES_FOR_CHAT_FULFILL:
            rooms[action.chatId] = __assign(__assign({}, action.messages), { results: addMessagesToList(((_f = rooms[action.chatId]) === null || _f === void 0 ? void 0 : _f.results) || [], ((_g = action.messages) === null || _g === void 0 ? void 0 : _g.results) || []) });
            return __assign(__assign({}, state), { messagesByRoom: rooms, messagesFetched: true, messagesFetching: false, lastMessageIdFetched: (_k = (_h = action.messages) === null || _h === void 0 ? void 0 : _h.results[((_j = action.messages) === null || _j === void 0 ? void 0 : _j.results.length) - 1]) === null || _k === void 0 ? void 0 : _k.id });
        case GET_NEXT_MESSAGES_FOR_CHAT_REJECT:
            return __assign(__assign({}, state), { fetchError: action.error, messagesFetched: true, messagesFetching: false });
        case GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH:
            return __assign(__assign({}, state), { messagesFetched: false, messagesFetching: true });
        case GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL:
            rooms[action.chatId] = __assign(__assign({}, action.messages), { results: addMessagesToList(((_l = rooms[action.chatId]) === null || _l === void 0 ? void 0 : _l.results) || [], ((_m = action.messages) === null || _m === void 0 ? void 0 : _m.results) || []) });
            return __assign(__assign({}, state), { messagesByRoom: rooms, messagesFetched: true, messagesFetching: false, lastMessageIdFetched: (_q = (_o = action.messages) === null || _o === void 0 ? void 0 : _o.results[((_p = action.messages) === null || _p === void 0 ? void 0 : _p.results.length) - 1]) === null || _q === void 0 ? void 0 : _q.id });
        case GET_PREVIOUS_MESSAGES_FOR_CHAT_REJECT:
            return __assign(__assign({}, state), { fetchError: action.error, messagesFetched: true, messagesFetching: false });
        case ADD_WS_MESSAGE_TO_LIST:
            rooms[action === null || action === void 0 ? void 0 : action.chatId].results = addMessagesToList(rooms[action === null || action === void 0 ? void 0 : action.chatId].results, [
                action === null || action === void 0 ? void 0 : action.message,
            ]);
            return __assign(__assign({}, state), { messagesByRoom: rooms, lastMessageIdFetched: action.message.id });
        case REMOVE_WS_MESSAGE_FROM_LIST:
            var msgs = rooms[action.chatId].results;
            var foundMessage = msgs.findIndex(function (_a) {
                var id = _a.id;
                return id === action.messageId;
            });
            if (foundMessage < 0)
                break;
            msgs.splice(foundMessage, 1);
            rooms[action.chatId].results = msgs;
            return __assign(__assign({}, state), { messagesByRoom: rooms });
        case ADD_WS_MESSAGE_LIKES_TO_LIST:
            var chatId = action.chatId, likes_count = action.likes_count, message_id_1 = action.message_id, isLike = action.isLike;
            if (rooms[chatId]) {
                var messageIndex = rooms[chatId].results.findIndex(function (msg) { return msg.id === message_id_1; });
                if (messageIndex !== -1) {
                    if (rooms[chatId].results[messageIndex]) {
                        if (!isLike) {
                            var likedIndex = (_s = (_r = rooms[chatId]) === null || _r === void 0 ? void 0 : _r.likedByMe) === null || _s === void 0 ? void 0 : _s.indexOf(message_id_1);
                            if (likedIndex !== -1) {
                                rooms[chatId].likedByMe.splice(likedIndex, 1);
                            }
                            rooms[chatId].results[messageIndex].likesCount = Math.max(0, rooms[chatId].results[messageIndex].likesCount - 1);
                            return __assign(__assign({}, state), { messagesByRoom: rooms });
                        }
                        else {
                            if (!((_t = rooms[chatId]) === null || _t === void 0 ? void 0 : _t.likedByMe.includes(message_id_1))) {
                                (_u = rooms[chatId]) === null || _u === void 0 ? void 0 : _u.likedByMe.push(message_id_1);
                            }
                            rooms[chatId].results[messageIndex].likesCount++;
                            return __assign(__assign({}, state), { messagesByRoom: rooms });
                        }
                    }
                }
            }
        default:
            return __assign({}, state);
    }
}
export var addMessagesToList = function (msgList, msgsToAdd) {
    var msgMap = new Map(msgList.map(function (msg) { return [msg.id, msg]; }));
    msgsToAdd.forEach(function (message) {
        if (!msgMap.has(message.id) || msgMap.get(message.id).content !== message.content) {
            msgMap.set(message.id, message);
        }
    });
    msgList.forEach(function (msg) {
        if (msg.repliedToMessage && msgsToAdd.some(function (newMsg) { return newMsg.id === msg.repliedToMessage.id; })) {
            msg.repliedToMessage = msgsToAdd.find(function (newMsg) { return newMsg.id === msg.repliedToMessage.id; });
            msgMap.set(msg.id, msg);
        }
    });
    return Array.from(msgMap.values()).sort(function (a, b) { return a.created.getTime() - b.created.getTime(); });
};
