var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ChatWindow from '../ChatWindow/ChatWindow';
import FullModal from '../FullModal/FullModal';
import Loader from '../Loader/Loader';
import { Preferences } from '@capacitor/preferences';
import s from './ChatModal.module.scss';
import { useHistory } from 'react-router';
import { useChatRooms, useMessages } from '../../util/APIDjango';
import BackIcon from '../../assets/images/back.svg';
// const mapStateToProps = ({ chat }: RootState, chatId: number) => ({
//   room: chat.rooms.find(({ id }) => id === chatId),
//   loading: chat.isFetching,
// });
/**
 * Displays a Chat room in a modal (like Posts)
 * @param {ChatModalProps} props
 * @returns {JSX.Element}
 */
var ChatModal = function (_a) {
    var chatId = _a.chatId;
    var _b = useState(true), autoTranslateStatus = _b[0], setAutoTranslateStatus = _b[1];
    var _c = useState(), room = _c[0], setRoom = _c[1];
    var _d = useMessages(), messagesData = _d[0], setMessagesData = _d[1];
    useEffect(function () {
        void Preferences.get({ key: 'autoTranslateStatus' }).then(function (result) {
            if (!result.value) {
                setAutoTranslateStatus(false);
            }
        });
        var tempRoom = rooms.find(function (_a) {
            var id = _a.id;
            return id === chatId;
        });
        setRoom(tempRoom);
    }, []);
    // const { room, loading } = useSelector(state =>
    //   mapStateToProps(state as RootState, chatId),
    // );
    var _e = useChatRooms(), chatRoomsData = _e[0], setChatRoomsData = _e[1];
    var isFetching = chatRoomsData.isFetching, rooms = chatRoomsData.rooms;
    var history = useHistory();
    var resetChatMessages = function () {
        setMessagesData({
            fetchError: null,
            isFetched: false,
            isFetching: false,
            lastMessageIdFetched: null,
            messagesByRoom: {
                likedByMe: [],
                next: null,
                previous: null,
                results: [],
            },
            messagesFetched: false,
            messagesFetching: false,
        });
    };
    var handleClose = function () {
        resetChatMessages();
        history.push('/chat');
    };
    if (isFetching) {
        return _jsx(Loader, {});
    }
    if (!room) {
        return _jsx(_Fragment, { children: "Error loading chat room data" });
    }
    if (chatId !== room.id) {
        return null;
    }
    return (_jsxs(FullModal, __assign({ className: s.wrapper, show: chatId === room.id, backDropClick: handleClose }, { children: [_jsx("div", __assign({ className: s.header }, { children: _jsx(ButtonIcon, { to: '/chat', icon: _jsx("img", { src: BackIcon }), fullWidth: true, label: autoTranslateStatus && (room === null || room === void 0 ? void 0 : room.translatedName) ? room === null || room === void 0 ? void 0 : room.translatedName : room.name, className: s.backButton }) })), _jsx(ChatWindow, { chatId: chatId })] })));
};
export default ChatModal;
