import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import UIContainer from '../components/UIContainer/UIContainer';
import DetailTitle from '../components/DetailTitle/DetailTitle';
import InterestsForm from '../forms/InterestsForm/InterestsForm';

const Interests = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <DetailTitle
        title={t('interests.label')}
        backLabel={t('settings.label')}
        backTo="/settings"
      />
      <UIContainer bottomSpace>
        <InterestsForm />
      </UIContainer>
    </Layout>
  );
};

export default Interests;
