import React, { useEffect, useState } from 'react';
import {
  updateLanguagesDispatch,
  updateLanguagesFulfill,
  updateLanguagesReject,
} from '../redux/modules/autoTranslate';

import DetailTitle from '../components/DetailTitle/DetailTitle';
import Layout from '../components/Layout/Layout';
import { Preferences } from '@capacitor/preferences';
import Toggle from './../components/Toggle/Toggle';
import UIContainer from './../components/UIContainer/UIContainer';
import UIListItem from '../components/UIListItem/UIListItem';
import api from './../util/api';
import s from './AutoTranslate.module.scss';
import styles from './LanguageSelection.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The AutoTranslater Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
const AutoTranslatePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isChanging, setIsChanging] = useState(false);
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    void Preferences.get({ key: 'language' }).then(result => {
      if (!result.value) {
        void Preferences.set({ key: 'language', value: 'en' });
      } else {
        setLanguage(result.value);
      }
    });

    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
  }, []);

  const toggleAutoTranslate = () => {
    const autoTranslatePreference = autoTranslateStatus ? 'false' : 'true';
    void Preferences.set({
      key: 'autoTranslateStatus',
      value: autoTranslatePreference,
    }).then(() => {
      const values = {
        language: language,
        autoTranslatePreference: !autoTranslateStatus,
      };
      dispatch(updateLanguagesDispatch(values));
      setIsChanging(true);
      api
        .autoTranslate(values)
        .then(response => {
          setAutoTranslateStatus(!autoTranslateStatus);
          dispatch(updateLanguagesFulfill(response));
        })
        .catch(error => {
          dispatch(updateLanguagesReject(error.message));
        });

      setIsChanging(false);
    });
  };

  return (
    <>
      <Layout>
        <DetailTitle
          title={t('autoTranslate.label')}
          backLabel={t('settings.label')}
          backTo="/settings"
        />
        <UIListItem contentClass={s.translateContent} className={s.translateWrapper}>
          <div className={[s.main].join(' ')}>
            <div className={s.content}>
              <p style={{ paddingRight: '20px' }}>{t('autoTranslationAbout.label')}</p>
            </div>
            <Toggle
              id={'1'}
              checked={autoTranslateStatus}
              pending={isChanging}
              onChange={toggleAutoTranslate}
              className={undefined}
              onClick={undefined}
            />
          </div>
        </UIListItem>
      </Layout>
    </>
  );
};

export default AutoTranslatePage;
