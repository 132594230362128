var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './DropZoneField.module.scss';
var getPlatform = Capacitor.getPlatform;
var platform = getPlatform();
var DropZoneVideoField = function (_a) {
    var acceptedMimeTypes = _a.acceptedMimeTypes, 
    // maxUploadSize,
    _b = _a.maxUploadSize, 
    // maxUploadSize,
    maxUploadSize = _b === void 0 ? 10485760 : _b, // 10mb
    _c = _a.icon, // 10mb
    icon = _c === void 0 ? _jsx(FontAwesomeIcon, { icon: ['fas', 'file-upload'] }) : _c, name = _a.name, label = _a.label, _d = _a.required, required = _d === void 0 ? false : _d, onChange = _a.onChange, errorText = _a.errorText, _e = _a.focused, focused = _e === void 0 ? false : _e, _f = _a.touched, touched = _f === void 0 ? false : _f, value = _a.value;
    var t = useTranslation().t;
    var _g = useState(null), videoData = _g[0], setVideoData = _g[1];
    var _h = useState(null), videoName = _h[0], setVideoName = _h[1];
    useEffect(function () {
        if (!value) {
            setVideoData(null);
            setVideoName(null);
        }
    }, [value]);
    var onDrop = useCallback(function (acceptedFiles, rejectedFiles) {
        if (rejectedFiles.length) {
            // TODO Display error when unsupported files added.
            rejectedFiles.forEach(function (file) {
                this.errorMessage = "".concat(file.name, " ").concat(t('fileTooLarge.label'));
                // show error message
            });
            return;
        }
        var reader = new FileReader();
        reader.onload = function (e) { return setVideoData(e.target.result); };
        reader.readAsDataURL(acceptedFiles[0]);
        setVideoName(acceptedFiles[0].name);
        onChange({ file: acceptedFiles[0] });
    }, []);
    var onFileChange = function (event) {
        var files = event.target.files;
        var reader = new FileReader();
        reader.onload = function (e) { return setVideoData(e.target.result); };
        reader.readAsDataURL(files[0]);
        setVideoName(files[0].name);
        onChange({ file: files[0] });
    };
    var _j = useDropzone({
        onDrop: onDrop,
        multiple: false,
        accept: acceptedMimeTypes.toString(),
        maxSize: maxUploadSize,
    }), getRootProps = _j.getRootProps, getInputProps = _j.getInputProps;
    var removeVideo = function () {
        setVideoData(null);
        setVideoName(null);
    };
    return (_jsxs(Fragment, { children: [_jsx(FieldLabel, { label: videoName ? "".concat(label, " (").concat(videoName, ")") : label, required: required }), _jsxs(FieldStatusBlock, __assign({ error: errorText, focused: focused, touched: touched }, { children: [platform === 'ios' ? (_jsx("div", __assign({ className: styles.trigger }, { children: _jsxs("label", __assign({ htmlFor: "videoUpload", className: styles.fileThumb, style: videoData ? { backgroundImage: "url(".concat(videoData, ")") } : null }, { children: [_jsx("input", { id: "videoUpload", name: name, type: "file", accept: acceptedMimeTypes.toString(), onChange: onFileChange, className: styles.input }), !videoData && (_jsx(FontAwesomeIcon, { icon: ['fas', 'video'], style: { pointerEvents: 'none' } }))] })) }))) : (_jsxs("div", __assign({}, getRootProps({
                        className: styles.trigger,
                    }), { children: [_jsx("input", __assign({}, getInputProps({
                                name: name,
                            }))), _jsx("div", __assign({ className: styles.fileThumb }, { children: !videoData ? icon : _jsx("img", { src: videoData, alt: "Video Thumbnail" }) }))] }))), videoData && (_jsx("div", __assign({ className: styles.clearText }, { children: _jsx("a", __assign({ onClick: removeVideo }, { children: "Clear" })) })))] }))] }));
};
export default DropZoneVideoField;
