var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertToRaw } from 'draft-js';
import { useCallback, useMemo, useRef, useState } from 'react';
import createMentionPlugin from '@draft-js-plugins/mention';
import Editor from '@draft-js-plugins/editor';
import MentionsEntry from './MentionsEntry';
import api from '../../util/api';
import editorStyles from './MentionsEditorChat.module.scss';
import mentionsStyles from './MentionsStylesChat.module.scss';
import { useTranslation } from 'react-i18next';
export var editorStateToString = function (state) {
    var contentState = state.getCurrentContent();
    var raw = convertToRaw(contentState);
    return raw.blocks
        .map(function (block) {
        var text = block.text, entityRanges = block.entityRanges;
        var tmp = text;
        entityRanges.reverse().forEach(function (_a) {
            var offset = _a.offset, length = _a.length, key = _a.key;
            var entity = raw.entityMap[key];
            if (entity.type === 'mention') {
                tmp = tmp.slice(0, offset) + "{{".concat(entity.data.mention.id, "}}") + tmp.slice(offset + length);
            }
        });
        return tmp;
    })
        .join('\n');
};
var MentionsEditor = function (_a) {
    var boardId = _a.boardId, disabled = _a.disabled, placeholder = _a.placeholder, editorState = _a.editorState, setEditorState = _a.setEditorState;
    var ref = useRef(null);
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState([]), suggestions = _c[0], setSuggestions = _c[1];
    var t = useTranslation().t;
    var _d = useMemo(function () {
        var mentionPlugin = createMentionPlugin({
            theme: mentionsStyles,
            mentionPrefix: '@',
        });
        var MentionSuggestions = mentionPlugin.MentionSuggestions;
        var plugins = [mentionPlugin];
        return { plugins: plugins, MentionSuggestions: MentionSuggestions };
    }, []), MentionSuggestions = _d.MentionSuggestions, plugins = _d.plugins;
    var onOpenChange = useCallback(setOpen, []);
    var onSearchChange = useCallback(function (_a) {
        var value = _a.value;
        if (value.length <= 1000) {
            value !== '' &&
                api.getMentionableUsersForBoard(value, boardId).then(function (users) {
                    setSuggestions(users);
                });
        }
    }, [boardId]);
    return (_jsxs("div", __assign({ className: editorStyles.editorWrapper }, { children: [_jsxs("div", __assign({ className: editorStyles.editor, style: { maxWidth: '100%' }, onClick: function () {
                    ref.current.focus();
                } }, { children: [_jsx(Editor, { editorKey: 'editor', editorState: editorState, onChange: setEditorState, plugins: plugins, ref: ref, placeholder: disabled ? t('noCommentsAllowed.label') : String(placeholder), spellCheck: true }), _jsx(MentionSuggestions, { open: open, onOpenChange: onOpenChange, suggestions: suggestions, onSearchChange: onSearchChange, onAddMention: function () {
                            setSuggestions([]);
                        }, entryComponent: MentionsEntry })] })), _jsxs("div", __assign({ className: editorStyles.helperText }, { children: [editorState.getCurrentContent().getPlainText('').length, " / 1000 ", t('characters.label')] }))] })));
};
export default MentionsEditor;
