import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import ActionableComment from '../Comment/ActionableComment';
import Loader from '../Loader/Loader';
import { MainContext } from '../../context/MainContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useProfile } from '../../util/APIDjango';
import api from '../../util/api';
import styles from './CommentList.module.scss';

// const mapStateToProps = ({ blocked }) => ({ blockedUsers: blocked.users });

const CommentList = ({ postId, isEnabled }) => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  // const { blockedUsers } = useSelector(mapStateToProps);
  const [profileData, setProfileData] = useProfile();

  const getProfileData = () => setProfileData({ ...profileData, isFetching: true });

  const profileDataFulfill = data =>
    setProfileData({
      ...data,
      profile: data,
      isFetching: false,
      isFetched: true,
      error: null,
    });

  const profileDataReject = error =>
    setProfileData({ ...profileData, error: error, isFetching: false });

  useEffect(() => {
    getProfileData();
    api
      .profileGet()
      .then(profileData => {
        profileDataFulfill(profileData);
      })
      .catch(err => {
        profileDataReject(err.message);
      });
  }, []);
  const { fetchCommentsForPost, commentsForPost } = useContext(MainContext);
  const [loaded, setLoaded] = useState(false);
  const [loadCount, setLoadCount] = useState(0);
  const commentState = commentsForPost(postId);
  const commentsError = commentState?.error;
  const fetching = commentState?.isFetching || false;
  const postComments = commentState?.comments || [];

  useEffect(() => {
    fetchCommentsForPost(postId);
  }, []);

  useEffect(() => {
    if (loadCount > 0) {
      setLoaded(!fetching);
    }
    if (fetching) {
      setLoadCount(loadCount + 1);
    }
  }, [fetching]);

  useEffect(() => {
    if (loaded) {
      setLoadCount(loadCount + 1);
    }
  }, [loaded]);

  const isBlockedAuthor = uuid => profileData.blockedUsers?.some(user => user.uuid === uuid);

  const renderComment = comment => {
    const isBlocked = isBlockedAuthor(comment?.author?.id);

    return (
      <ActionableComment
        key={comment.id}
        isBlocked={isBlocked}
        comment={{ ...comment, created: new Date(comment?.created) }}
      />
    );
  };

  const title = () => {
    if (!isEnabled) {
      return t('noCommentsAllowed.label');
    }
    if (fetching) {
      return t('fetchingComments.label');
    }
    if (postComments.length < 1) {
      return t('noComments.label');
    }
    return t('comments.label');
  };

  return (
    <div>
      <div className={styles.title}>{title()}</div>
      {isEnabled && (
        <Fragment>
          {commentsError ? (
            <div>{commentsError}</div>
          ) : (
            postComments.map(comment => renderComment(comment))
          )}
          {fetching && <Loader />}
          <div ref={scrollRef} />
        </Fragment>
      )}
    </div>
  );
};

CommentList.propTypes = {
  postId: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default CommentList;
