// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-PageTitle-module-title-WsQjH{font-family:"nexa";font-size:32px;font-style:normal;font-weight:700;line-height:48px;color:#eee;padding:0 16px;margin:0}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/PageTitle.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,+BACE,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,UC2Ca,CD1Cb,cAAA,CACA,QAAA","sourcesContent":["@import \"../../theme/colors\";\n\n.title {\n  font-family: \"nexa\";\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 48px;\n  color: $primaryColor;\n  padding: 0 16px;\n  margin: 0;\n}\n","$yellow: #fcdb00;\n$yellow-main: #ffd700;\n$yellow-dark: #f9ad00;\n$yellow-message: #FFD900;\n$yellow-reply: #FFF099;\n$red: #f1321e;\n$green-400: #66bb6a;\n$green: #00ff00;\n$orange-500: #ff9800;\n$black: #000000;\n$white-800: #fefefe;\n$white: #ffffff;\n$invictus-white: #fff;\n\n$grey-50: #eceff1;\n$grey-100: #cfd8dc;\n$grey-200: #b0bec5;\n$grey-300: #90a4ae;\n$grey-400: #78909c;\n$grey-500: #607d8b;\n$grey-600: #546e7a;\n$grey-700: #455a64;\n$grey-800: #37474f;\n$grey-900: #263238;\n$grey-reply: #CCCCCC;\n$grey-message: #999999;\n$invictus-2nd-dark-grey: #666;\n$invictus-3rd-dark-grey: #333;\n\n$primary: $yellow;\n$secondary: $grey-600;\n$success: $green-400;\n$warning: $orange-500;\n$danger: $red;\n\n\n// new design colors\n\n// backgrounds\n$appBackground: #111;\n$buttonBackground: #FFD700;\n$buttonHoverBackground: #F1CE36;\n$cardBackground: #222;\n$disabledButtonBg: #191919;\n$inputBackground: #2A2A2A;\n$inputActiveBg: #313131;\n$mentionBG: #6E6E6E;\n$backdropColor: rgba(17, 17, 17, 0.50);\n\n\n// text colors\n$primaryColor: #eee;\n$secondaryColor: #B3B3B3;\n$buttonColor: #000;\n$errorColor: #FF977D;\n$likeButtonColor: #E54D2E;\n$successColor: #29A383;\n\n\n//\n$separator: #3a3a3a;\n$borderColor: #484848;\n$inputBorder: #606060;\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `-PageTitle-module-title-WsQjH`
};
export default ___CSS_LOADER_EXPORT___;
