var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './DropZoneField.module.scss';
import clsx from 'clsx';
import { Close } from '../Icons/Close';
import { Play } from '../Icons/Play';
var DropZoneField = function (_a) {
    var _b;
    var acceptedMimeTypes = _a.acceptedMimeTypes, maxUploadSize = _a.maxUploadSize, // 10mb
    _c = _a.icon, // 10mb
    icon = _c === void 0 ? _jsx(FontAwesomeIcon, { icon: ['fas', 'file-upload'] }) : _c, _d = _a.label, label = _d === void 0 ? null : _d, _e = _a.onChange, onChange = _e === void 0 ? null : _e, _f = _a.errorText, errorText = _f === void 0 ? null : _f, _g = _a.focused, focused = _g === void 0 ? false : _g, _h = _a.touched, touched = _h === void 0 ? false : _h, thumbnail = _a.thumbnail, value = _a.value, video = _a.video;
    var _j = useState(null), imageData = _j[0], setImageData = _j[1];
    var _k = useState(null), imageName = _k[0], setImageName = _k[1];
    useEffect(function () {
        if (!value) {
            setImageData(null);
            setImageName(null);
        }
    }, [value]);
    var onDrop = useCallback(function (acceptedFiles, rejectedFiles) {
        if (rejectedFiles.length) {
            // TODO Display error when unsupported files added.
            rejectedFiles.forEach(function () {
                // this.errorMessage = `${file.name} ${t('fileTooLarge.label')}`;
                // show error message
            });
            return;
        }
        var reader = new FileReader();
        reader.onload = function (e) { return setImageData(e.target.result); };
        reader.readAsDataURL(acceptedFiles[0]);
        setImageName(acceptedFiles[0].name);
        onChange(acceptedFiles[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onFileChange = function (event) {
        var files = event.target.files;
        var reader = new FileReader();
        reader.onload = function (e) { return setImageData(e.target.result); };
        reader.readAsDataURL(files[0]);
        setImageName(files[0].name);
        onChange(files[0]);
    };
    // eslint-disable-next-line @typescript-eslint/unbound-method
    var _l = useDropzone({
        onDrop: onDrop,
        multiple: false,
        accept: acceptedMimeTypes.toString(),
        maxSize: maxUploadSize !== null && maxUploadSize !== void 0 ? maxUploadSize : 10485760,
    }), getRootProps = _l.getRootProps, getInputProps = _l.getInputProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var removeImage = function (e) {
        e.stopPropagation();
        setImageData(null);
        setImageName(null);
        onChange();
    };
    return (_jsx("div", __assign({ className: styles.fieldWrapper }, { children: _jsx(FieldStatusBlock, __assign({ error: errorText, focused: focused, touched: touched, className: styles.statusBlock }, { children: _jsxs("div", __assign({}, getRootProps({
                className: styles.trigger,
            }), { children: [_jsx("input", __assign({}, getInputProps({}))), thumbnail && imageData && (_jsx("a", __assign({ onClick: removeImage, className: styles.removeThumb }, { children: _jsx(Close, {}) }))), _jsx("div", __assign({ className: clsx(styles.fileThumb, (_b = {},
                            _b[styles.fileSelected] = !!imageData && !thumbnail,
                            _b[styles.thumbnail] = thumbnail,
                            _b)), style: imageData && thumbnail && !video ? { backgroundImage: "url(".concat(imageData, ")") } : null }, { children: !imageData ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.uploadIcon }, { children: [icon, label] })), maxUploadSize && (_jsxs("div", __assign({ className: styles.maxUploadSize }, { children: ["Maximum size: ", _jsx("span", { children: "".concat(Math.round(maxUploadSize / 1024 / 1024), "MB") })] })))] })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.selectedDocument }, { children: [imageName, _jsx("div", __assign({ className: styles.clearText }, { children: _jsx("a", __assign({ onClick: removeImage }, { children: "Clear" })) }))] })), video && (_jsxs(_Fragment, { children: [_jsx("video", { src: imageData, className: styles.videoThumb }), _jsx("div", __assign({ className: styles.playIcon }, { children: _jsx(Play, {}) }))] }))] })) }))] })) })) })));
};
export default DropZoneField;
