import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FEATURE_FLAGS } from '../../util/config';
import { AuthContext } from '../../context/AuthProvider';
import { commentType } from '../../proptypes/comment';
import FlagForm from '../../forms/FlagForm/FlagForm';
import ActionSheet from '../ActionSheet/ActionSheet';
import UIListItem from '../UIListItem/UIListItem';
import UIBlockListItem from '../UIBlockListItem/UIBlockListItem';
import HideShow from '../HideShow/HideShow';
import Comment from './Comment';
import RemoveCommentListItem from '../RemoveCommentListItem/RemoveCommentListItem';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';
import { useAuth } from '../../util/APIDjango';
import { Translate } from '../Icons/Translate';
import clsx from 'clsx';
import styles from './Comment.module.scss';
import { Info } from '../Icons/Info';

const ActionableComment = ({ comment, isBlocked }) => {
  const { t } = useTranslation();
  // const { uid } = useContext(AuthContext);
  const [authState, setAuthState] = useAuth();
  const { uid } = authState;

  const { translate, translated, translation, translationError } = useGoogleTranslate(
    comment?.content,
  );
  const [translatableComment, setTranslatableComment] = useState(comment);
  const [activeActionsheet, setActiveActionSheet] = useState(null);
  const [activeFlagForm, setActiveFlagForm] = useState(null);
  // used to stop actionsheet updating on block.
  const [delayedIsBlocked, setDelayedIsBlocked] = useState(null);
  const usingTranslation = translatableComment.content === translation;

  const toggleActionSheet = id => {
    if (id === null) {
      setActiveFlagForm(null);
      setDelayedIsBlocked(isBlocked);
    }
    setActiveActionSheet(id);
  };

  const handleTranslate = () => {
    if (!translated) {
      translate();
    } else if (usingTranslation) {
      setTranslatableComment(comment);
    } else {
      setTranslatableComment({ ...comment, content: translation });
    }
    toggleActionSheet(null);
  };

  useEffect(() => setDelayedIsBlocked(isBlocked), []);
  useEffect(() => {
    if (translation) {
      setTranslatableComment({ ...comment, content: translation });
    }
  }, [translation]);

  return (
    <Fragment>
      <Comment
        translatedMessage={usingTranslation ? t('translationByGoogle.label') : null}
        translationError={translationError}
        isBlocked={isBlocked}
        comment={translatableComment}
        onClick={() => toggleActionSheet(comment?.id)}
      />
      <ActionSheet
        show={activeActionsheet === comment?.id}
        backDropClick={() => toggleActionSheet(null)}>
        {delayedIsBlocked ? (
          <Fragment>
            <UIListItem icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}>
              Unblock user?
            </UIListItem>
            <UIBlockListItem id={comment?.author?.username} author={comment?.author}>
              {comment?.author?.username}
            </UIBlockListItem>
          </Fragment>
        ) : (
          <Fragment>
            {comment?.author?.id === uid ? (
              <RemoveCommentListItem commentId={comment?.id} postId={comment?.postId} />
            ) : (
              <Fragment>
                {FEATURE_FLAGS.googleTranslate && (
                  <UIListItem onClick={handleTranslate} icon={<Translate />}>
                    {usingTranslation ? t('removeTranslation.label') : t('translateComment.label')}
                  </UIListItem>
                )}
                <UIListItem
                  onClick={() => setActiveFlagForm(comment?.id)}
                  icon={<Info />}
                  activeButton={activeFlagForm === comment?.id}
                  className={clsx(activeFlagForm === comment?.id && styles.flagOpen)}>
                  {t('flagBlock.label')}
                </UIListItem>
                <HideShow show={activeFlagForm === comment?.id}>
                  <FlagForm
                    author={comment?.author}
                    id={comment?.id}
                    type="comment"
                    handleCancel={() => toggleActionSheet(null)}
                  />
                </HideShow>
              </Fragment>
            )}
          </Fragment>
        )}
      </ActionSheet>
    </Fragment>
  );
};

ActionableComment.propTypes = {
  comment: PropTypes.shape(commentType).isRequired,
  isBlocked: PropTypes.bool,
};

ActionableComment.defaultProps = {
  isBlocked: false,
};

export default ActionableComment;
