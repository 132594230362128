import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { ENVIRONMENT } from '../util/config';
import { rootReducer } from './modules/root';
import rootEpic from './epics/rootEpic';
import { notificationReadListener } from './listeners/PushNotification';

const middleware = [];
const epicMiddleware = createEpicMiddleware();

middleware.push(epicMiddleware);

const debug = ENVIRONMENT !== 'production';
if (debug) {
  // Logs all actions and states after they are dispatched.
  const logger = store => next => action => {
    const result = next(action);
    return result;
  };
  middleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

epicMiddleware.run(rootEpic);

notificationReadListener(store.dispatch, store.getState);

export default store;
