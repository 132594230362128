var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './CheckboxButton.module.scss';
var handleKeyUp = function (e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
        e.target.click();
    }
};
var CheckboxButton = function (_a) {
    var checked = _a.checked, label = _a.label, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, id = _a.id, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var labelStyles = [styles.button, checked && styles.active, disabled && styles.disabled].join(' ');
    return (_jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsx("input", { className: styles.checkbox, id: id, type: "checkbox", value: value, checked: !!value, onChange: onChange, onBlur: onBlur, disabled: disabled }), _jsx("label", __assign({ htmlFor: id, tabIndex: 0, onKeyUp: handleKeyUp, className: labelStyles }, { children: label }))] })));
};
export default CheckboxButton;
