import Footer from '../Footer/Footer';
import React from 'react';
import TabNav from '../TabNav/TabNav';
import { isNative } from '../../util/isNative';
import styles from './Layout.module.scss';
import trackPrevPath from '../../util/trackPrevPath';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import clsx from 'clsx';

const Layout = ({ children, location, className }) => {
  const { pathname } = useLocation();
  trackPrevPath(location.pathname);
  const wrapperClasses = [
    styles.wrapper,
    isNative && styles.native,
    pathname.includes('chat') && styles.wrapperChat,
  ].join(' ');

  const mainClasses = clsx(
    styles.wrapper,
    styles.main,
    pathname.includes('chat') && styles.chat,
    className,
  );

  return (
    <div className={wrapperClasses}>
      <TabNav className={styles.tabNav} />
      <main className={mainClasses}>{children}</main>
      {!isNative && <Footer className={styles.footer} />}
    </div>
  );
};

export default withRouter(Layout);
