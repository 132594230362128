var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useRef, useState } from 'react';
import ActionSheet from '../ActionSheet/ActionSheet';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { Capacitor } from '@capacitor/core';
import CommentForm from '../../forms/CommentForm/CommentForm';
import CommentList from '../CommentList/CommentList';
import DOMPurify from 'dompurify';
import { FEATURE_FLAGS } from '../../util/config';
import FlagForm from '../../forms/FlagForm/FlagForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HideShow from '../HideShow/HideShow';
import Img from '../Img/Img';
import { Preferences } from '@capacitor/preferences';
import RemovePostListItem from '../RemovePostListItem/RemovePostListItem';
import SavePostListItem from '../SavePostListItem/SavePostListItem';
import UIListItem from '../UIListItem/UIListItem';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import moment from 'moment';
import styles from './PostDetail.module.scss';
import useBoard from '../../hooks/useBoard';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';
import usePostLike from '../../hooks/usePostLike';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../util/APIDjango';
import { Close } from '../Icons/Close';
import { Info } from '../Icons/Info';
import { DotMenu } from '../Icons/DotMenu';
import clsx from 'clsx';
import { Translate } from '../Icons/Translate';
export var LikedIcon = function () { return (_jsx("svg", __assign({ "aria-hidden": "true", focusable: "false", "data-prefix": "far", "data-icon": "heart", className: "svg-inline--fa fa-heart", role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, { children: _jsx("path", { fill: "currentColor", stroke: "currentColor", strokeWidth: "30px", d: "M462.1 62.86C438.8 41.92 408.9 31.1 378.7 32c-37.49 0-75.33 15.4-103 43.98l-19.7 20.27l-19.7-20.27C208.6 47.4 170.8 32 133.3 32C103.1 32 73.23 41.93 49.04 62.86c-62.14 53.79-65.25 149.7-9.23 207.6l193.2 199.7C239.4 476.7 247.6 480 255.9 480c8.332 0 16.69-3.267 23.01-9.804l193.1-199.7C528.2 212.5 525.1 116.6 462.1 62.86z" }) }))); };
var PostDetail = function (_a) {
    var _b, _c;
    var _d = _a.post, post = _d === void 0 ? null : _d, className = _a.className, onClose = _a.onClose, commentId = _a.commentId;
    // const { uid } = useContext(AuthContext);
    var _e = useAuth(), authState = _e[0], setAuthState = _e[1];
    var uid = authState.uid;
    var _f = useGoogleTranslate(post === null || post === void 0 ? void 0 : post.content), translate = _f.translate, translated = _f.translated, translation = _f.translation, translationError = _f.translationError;
    var board = useBoard(post.boardId).board;
    var _g = useState(post), translatablePost = _g[0], setTranslatablePost = _g[1];
    var _h = usePostLike(post.id), toggleLiked = _h.toggleLiked, isLiked = _h.isLiked;
    var _j = useState(null), activeActionsheet = _j[0], setActiveActionSheet = _j[1];
    var _k = useState(null), activeFlagForm = _k[0], setActiveFlagForm = _k[1];
    var t = useTranslation().t;
    var sanitizer = DOMPurify.sanitize;
    var usingTranslation = translatablePost.content === translation;
    var translatedMessage = usingTranslation ? t('translationByGoogle.label') : null;
    var _l = useState(), likeButtonLabel = _l[0], setLabel = _l[1];
    var initiallyLiked = useRef(isLiked);
    var scrollBoxRef = useRef();
    var getPlatform = Capacitor.getPlatform;
    var platform = getPlatform();
    var shouldShowControls = platform !== 'ios';
    var shouldAutoplay = platform === 'ios';
    var _m = useState(true), autoTranslateStatus = _m[0], setAutoTranslateStatus = _m[1];
    useEffect(function () {
        void Preferences.get({ key: 'autoTranslateStatus' }).then(function (result) {
            if (!result.value) {
                setAutoTranslateStatus(false);
            }
        });
    }, []);
    useEffect(function () {
        if (!translation && autoTranslateStatus) {
            handleTranslate();
        }
        else {
            setTranslatablePost(__assign(__assign({}, post), { content: translation }));
        }
    }, [translation]);
    useEffect(function () {
        if (initiallyLiked.current && isLiked) {
            setLabel(String(post.likesCount));
        }
        else if (initiallyLiked.current && !isLiked) {
            setLabel(String(post.likesCount - 1));
        }
        else if (!initiallyLiked.current && isLiked) {
            setLabel(String(post.likesCount + 1));
        }
        else if (!initiallyLiked.current && !isLiked) {
            setLabel(String(post.likesCount ? post.likesCount : 0));
        }
    }, [isLiked, post.likesCount]);
    if (!post) {
        return null;
    }
    var id = post.id, title = post.title, translatedTitle = post.translatedTitle, author = post.author, created = post.created, image = post.image, video = post.video, isCommentAllowed = post.isCommentAllowed, opportunityDetails = post.opportunityDetails;
    var wrapperClasses = clsx(styles.wrapper, className, !isCommentAllowed && styles.disabledCommnets);
    var toggleActionSheet = function (postId) {
        if (postId === null) {
            setActiveFlagForm(null);
        }
        setActiveActionSheet(postId);
    };
    var handleTranslate = function () {
        if (!translated) {
            translate();
        }
        else if (usingTranslation) {
            setTranslatablePost(post);
        }
        else {
            setTranslatablePost(__assign(__assign({}, post), { content: translation }));
        }
        toggleActionSheet(null);
    };
    var renderActionSheetContent = function () {
        var savePostListItem = _jsx(SavePostListItem, { postId: post === null || post === void 0 ? void 0 : post.id });
        if ((author === null || author === void 0 ? void 0 : author.id) === uid) {
            return (_jsxs(Fragment, { children: [FEATURE_FLAGS.savedPosts && savePostListItem, _jsx(RemovePostListItem, { postId: post === null || post === void 0 ? void 0 : post.id })] }));
        }
        if ((post === null || post === void 0 ? void 0 : post.postType) === 'content' || (post === null || post === void 0 ? void 0 : post.postType) === 'opportunity') {
            return (_jsxs(Fragment, { children: [FEATURE_FLAGS.boards && (board === null || board === void 0 ? void 0 : board.boardId) && (_jsx(UIListItem, __assign({ to: "/boards/".concat(board.boardId), icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'th-large'] }) }, { children: t('viewBoard.label') }))), FEATURE_FLAGS.savedPosts && savePostListItem, FEATURE_FLAGS.googleTranslate && (_jsx(UIListItem, __assign({ onClick: handleTranslate, icon: _jsx(Translate, {}) }, { children: usingTranslation ? t('removeTranslation.label') : t('translatePost.label') }))), (post === null || post === void 0 ? void 0 : post.postType) === 'content' && (_jsxs(Fragment, { children: [_jsx(UIListItem, __assign({ onClick: function () { return setActiveFlagForm(post === null || post === void 0 ? void 0 : post.id); }, icon: _jsx(Info, {}), activeButton: activeFlagForm === (post === null || post === void 0 ? void 0 : post.id), className: clsx(activeFlagForm === (post === null || post === void 0 ? void 0 : post.id) && styles.flagOpen) }, { children: t('flagBlock.label') })), _jsx(HideShow, __assign({ show: activeFlagForm === (post === null || post === void 0 ? void 0 : post.id) }, { children: _jsx(FlagForm, { author: post === null || post === void 0 ? void 0 : post.author, id: post === null || post === void 0 ? void 0 : post.id, type: "post", handleCancel: function () { return toggleActionSheet(null); } }) }))] }))] }));
        }
        return FEATURE_FLAGS.savedPosts ? savePostListItem : _jsx(UIListItem, { children: "No Options" });
    };
    return (_jsxs("div", __assign({ className: wrapperClasses }, { children: [_jsxs("div", __assign({ className: styles.meta }, { children: [_jsx(Avatar, { className: styles.avatar, src: (_b = author === null || author === void 0 ? void 0 : author.avatar) === null || _b === void 0 ? void 0 : _b.src, alt: author === null || author === void 0 ? void 0 : author.username }), _jsxs("div", __assign({ className: styles.titles }, { children: [_jsx("div", __assign({ className: styles.title }, { children: uid !== (author === null || author === void 0 ? void 0 : author.id) && autoTranslateStatus && translatedTitle ? translatedTitle : title })), _jsxs("div", __assign({ className: styles.subtitle }, { children: [author === null || author === void 0 ? void 0 : author.username, " - ", moment(created).fromNow()] }))] })), _jsx(ButtonIcon, { title: "Close", className: styles.close, onClick: onClose, icon: _jsx(Close, {}) })] })), _jsxs("div", __assign({ className: styles.detail, ref: scrollBoxRef }, { children: [video && (_jsx(VideoPlayer, { sources: [
                            video.dash ? { src: video.dash.url, type: video.dash.mimeType } : undefined,
                            video.hls ? { src: video.hls.url, type: video.hls.mimeType } : undefined,
                            video.mp4 ? { src: video.mp4.url, type: video.mp4.mimeType } : undefined,
                        ], poster: video.thumbnail.url, controls: shouldShowControls, autoplay: shouldAutoplay })), !video && (image === null || image === void 0 ? void 0 : image.src) && (_jsx(Img, { className: styles.image, src: image.src, alt: title, ratio: image.ratio })), _jsxs("div", __assign({ className: styles.body }, { children: [_jsxs("div", __assign({ className: styles.contentWrapper }, { children: [_jsxs("div", __assign({ className: styles.contentMeta }, { children: [FEATURE_FLAGS.boards && board && (_jsx(Avatar, { className: styles.avatar, src: (_c = board === null || board === void 0 ? void 0 : board.image) === null || _c === void 0 ? void 0 : _c.src, alt: board === null || board === void 0 ? void 0 : board.name })), _jsx("div", __assign({ className: styles.boardMeta }, { children: FEATURE_FLAGS.boards && (board === null || board === void 0 ? void 0 : board.name) })), FEATURE_FLAGS.likedPosts && (
                                            // Initial POC, hooked up to actual api
                                            _jsx(ButtonIcon, { title: "like", onClick: function () { return toggleLiked(); }, label: likeButtonLabel, className: clsx(styles.icon, isLiked && styles.liked), icon: isLiked ? _jsx(LikedIcon, {}) : _jsx(FontAwesomeIcon, { icon: ['far', 'heart'] }) })), _jsx("div", __assign({ className: styles.options }, { children: _jsx(ButtonIcon, { title: "Options", onClick: function () { return toggleActionSheet(id); }, className: styles.icon, icon: _jsx(DotMenu, {}) }) }))] })), _jsx("div", { className: styles.content, dangerouslySetInnerHTML: {
                                            __html: (post === null || post === void 0 ? void 0 : post.translatedContent) || sanitizer(translatablePost.content),
                                        } })] })), translationError && _jsx("div", __assign({ className: styles.translationError }, { children: translationError })), !translationError && translatedMessage && (_jsx("div", __assign({ className: styles.translatedMessage }, { children: translatedMessage }))), opportunityDetails && opportunityDetails.link && (_jsx(Fragment, { children: _jsx(Button, { label: opportunityDetails.label || t('apply.label'), fullWidth: true, link: opportunityDetails.link }) })), opportunityDetails && opportunityDetails.email && (_jsx(Fragment, { children: _jsx(Button, { label: opportunityDetails.label || t('apply.label'), fullWidth: true, link: "mailto:".concat(opportunityDetails.email) }) })), isCommentAllowed && _jsx(CommentForm, { postId: id, boardId: post.boardId }), _jsx(CommentList, { postId: id, isEnabled: isCommentAllowed })] }))] })), _jsx(ActionSheet, __assign({ show: activeActionsheet === (post === null || post === void 0 ? void 0 : post.id), backDropClick: function () { return toggleActionSheet(null); } }, { children: renderActionSheetContent() }))] })));
};
export default PostDetail;
