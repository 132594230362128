var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Avatar from '../Avatar/Avatar';
import CommentMention from '../CommentMention/CommentMention';
import { Preferences } from '@capacitor/preferences';
import { decodeEntity } from 'html-entities';
import defaultAvatar from '../../assets/images/default-avatar.svg';
import moment from 'moment';
import styles from './Comment.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../util/APIDjango';
var decodeCommentText = function (comment) {
    var matches = [];
    var match = [''];
    var restOfText = comment;
    while (match !== null) {
        restOfText = restOfText.substring(match.index + match[0].length);
        match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
        if (match !== null) {
            matches.push({
                entity: match[0],
                index: comment.length - restOfText.length + match.index,
            });
        }
    }
    var returnString = comment;
    matches.forEach(function (elem) {
        returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
    });
    return returnString;
};
var Comment = function (_a) {
    var _b, _c, _d, _e, _f;
    var comment = _a.comment, _g = _a.isBlocked, isBlocked = _g === void 0 ? false : _g, _h = _a.translatedMessage, translatedMessage = _h === void 0 ? null : _h, _j = _a.translationError, translationError = _j === void 0 ? null : _j, onClick = _a.onClick;
    var _k = useState(true), autoTranslateStatus = _k[0], setAutoTranslateStatus = _k[1];
    useEffect(function () {
        void Preferences.get({ key: 'autoTranslateStatus' }).then(function (result) {
            if (!result.value) {
                setAutoTranslateStatus(false);
            }
        });
    }, []);
    var t = useTranslation().t;
    // const { uid } = useContext(AuthContext);
    var _l = useAuth(), authState = _l[0], setAuthState = _l[1];
    var uid = authState.uid;
    var username = isBlocked ? 'Blocked' : (_b = comment === null || comment === void 0 ? void 0 : comment.author) === null || _b === void 0 ? void 0 : _b.username;
    var avatarSrc = isBlocked ? defaultAvatar : (_d = (_c = comment === null || comment === void 0 ? void 0 : comment.author) === null || _c === void 0 ? void 0 : _c.avatar) === null || _d === void 0 ? void 0 : _d.src;
    var content = uid !== ((_e = comment === null || comment === void 0 ? void 0 : comment.author) === null || _e === void 0 ? void 0 : _e.id) && (comment === null || comment === void 0 ? void 0 : comment.translatedContent) && autoTranslateStatus
        ? comment === null || comment === void 0 ? void 0 : comment.translatedContent
        : isBlocked
            ? t('blockedContent.label')
            : comment === null || comment === void 0 ? void 0 : comment.content;
    var mentions = isBlocked || (comment.mentions && ((_f = comment.mentions) === null || _f === void 0 ? void 0 : _f.length) === 0)
        ? undefined
        : comment.mentions;
    var wrapperClasses = [styles.wrapper, isBlocked && styles.blocked].join(' ');
    var buildComment = function (commentText) {
        if (!mentions)
            return [_jsx(_Fragment, { children: commentText })];
        var mentionEndingIdentifier = comment.mentionEndingIdentifier, mentionStartingIdentifier = comment.mentionStartingIdentifier;
        var tmp = commentText.split(mentionStartingIdentifier);
        var commentParts = tmp.map(function (part) {
            if (part.includes(mentionEndingIdentifier)) {
                var splitLine_1 = part.split(mentionEndingIdentifier);
                var mentionedUser = mentions.find(function (user) { return user.id == splitLine_1[0]; });
                return (_jsxs(_Fragment, { children: [_jsx(CommentMention, { className: styles.mention, user: mentionedUser }), splitLine_1[1]] }));
            }
            return _jsx(_Fragment, { children: part });
        });
        return commentParts;
    };
    return (_jsxs("div", __assign({ id: comment === null || comment === void 0 ? void 0 : comment.id, className: wrapperClasses }, { children: [_jsx(Avatar, { className: styles.avatar, src: avatarSrc, alt: username }), _jsxs("div", __assign({ className: styles.body }, { children: [_jsx("div", __assign({ className: styles.comment, onClick: onClick, onKeyUp: function (e) { return (e.keyCode === 13 ? onClick() : null); }, role: "button", tabIndex: 0 }, { children: _jsxs("div", __assign({ className: styles.content }, { children: [_jsx("div", __assign({ className: styles.name }, { children: username })), _jsxs("p", __assign({ className: [styles.content, mentions && styles.extendLineHeight].join(' ') }, { children: __spreadArray([], buildComment(decodeCommentText(content)), true) }))] })) })), _jsxs("div", __assign({ className: styles.metaWrapper }, { children: [_jsx("div", __assign({ className: styles.created }, { children: moment(comment === null || comment === void 0 ? void 0 : comment.created).fromNow() })), translationError && _jsx("div", __assign({ className: styles.translationError }, { children: translationError })), !translationError && translatedMessage && (_jsx("div", __assign({ className: styles.translatedMessage }, { children: translatedMessage })))] }))] }))] }), comment === null || comment === void 0 ? void 0 : comment.id));
};
export default Comment;
