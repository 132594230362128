var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './SelectField.module.scss';
import SelectList from 'react-select';
import clsx from 'clsx';
var SelectField = function (_a) {
    var className = _a.className, _b = _a.required, required = _b === void 0 ? false : _b, errorText = _a.errorText, name = _a.name, value = _a.value, _c = _a.items, items = _c === void 0 ? [] : _c, label = _a.label, _d = _a.touched, touched = _d === void 0 ? false : _d, _e = _a.disabled, disabled = _e === void 0 ? false : _e, _f = _a.numVal, numVal = _f === void 0 ? false : _f, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur;
    var t = useTranslation().t;
    var _g = useState(false), focused = _g[0], setFocused = _g[1];
    var getLabelForValue = function () {
        var defaultLabel = t('select.label');
        if (items instanceof Map) {
            return items.get(value) || defaultLabel;
        }
        if (items instanceof Array) {
            var selectedItem = items.find(function (item) {
                if (typeof item.key === 'number') {
                    return item.key === parseInt(value, 10);
                }
                return item.key === value;
            });
            return selectedItem ? selectedItem.description : defaultLabel;
        }
        return items[value] || defaultLabel;
    };
    var handleFocus = function (e) {
        if (onFocus) {
            onFocus(e);
        }
        setFocused(true);
    };
    var handleBlur = function (e) {
        if (onBlur) {
            onBlur(e);
        }
        setFocused(false);
    };
    var handleChange = function (e) {
        return onChange(numVal ? parseInt(e.target.value, 10) : e.target.value);
    };
    var renderOptions = function () {
        if (items instanceof Array) {
            return (_jsx(Fragment, { children: items.map(function (item) { return (_jsx("option", __assign({ value: item.key }, { children: item.description }), item.key)); }) }));
        }
        var options = items instanceof Map ? Array.from(items.entries()) : Object.entries(items);
        return (_jsx(Fragment, { children: options.map(function (_a) {
                var key = _a[0], description = _a[1];
                return (_jsx("option", __assign({ value: key }, { children: description }), key));
            }) }));
    };
    var selectClasses = [styles.selectButton, value && styles.selectButtonValue].join(' ');
    var wrapperClasses = [
        className,
        styles.wrapper,
        errorText && styles.wrapperError,
        focused && styles.wrapperActive,
    ].join(' ');
    return (_jsxs("div", __assign({ className: wrapperClasses }, { children: [_jsx(FieldLabel, { label: label, required: required }), _jsx(FieldStatusBlock, __assign({ error: errorText, focused: focused, touched: touched }, { children: _jsxs("div", __assign({ className: styles.fieldWrapper }, { children: [_jsx("div", __assign({ className: selectClasses }, { children: getLabelForValue() })), _jsx("div", __assign({ className: styles.selectWrapper }, { children: _jsxs("select", __assign({ disabled: disabled, name: name, value: value || 'none', onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange }, { children: [_jsx("option", __assign({ value: "none", disabled: true }, { children: t('select.label') })), renderOptions()] })) }))] })) }))] })));
};
export var FormikSelect = function (_a) {
    var _b;
    var options = _a.options, field = _a.field, form = _a.form, placeholder = _a.placeholder, className = _a.className, onChange = _a.onChange, rest = __rest(_a, ["options", "field", "form", "placeholder", "className", "onChange"]);
    // #TODO: IGA-122
    useEffect(function () {
        void form.validateField(field.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);
    return (_jsx("div", __assign({ className: clsx(styles.select, className) }, { children: _jsx(SelectList, __assign({ options: options, placeholder: placeholder, name: field.name, onChange: function (option) {
                void form.setFieldValue(field.name, option.value, true);
                onChange && onChange(option);
            }, onBlur: field.onBlur, classNamePrefix: "invictus", isSearchable: false, value: (_b = options.find(function (option) { return option.value === field.value; })) !== null && _b !== void 0 ? _b : null }, rest)) })));
};
export default SelectField;
