// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatWindow-module-scrollWindow-BfDEb{width:100%;flex:1;overflow-y:auto;overflow-x:hidden}`, "",{"version":3,"sources":["webpack://./src/components/ChatWindow/ChatWindow.module.scss"],"names":[],"mappings":"AAEA,uCACE,UAAA,CACA,MAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":["@import \"../../theme/\";\n\n.scrollWindow {\n  width: 100%;\n  flex: 1;\n  overflow-y: auto;\n  overflow-x: hidden;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollWindow": `-ChatWindow-module-scrollWindow-BfDEb`
};
export default ___CSS_LOADER_EXPORT___;
